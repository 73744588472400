<spot-drop-modal
  [opened]="opened"
  (closed)="onModalClosed()"
  alignment="bottom-center"
>
  <input
    slot="trigger"
    type="text"
    class="spot-input"
    (click)="onInputClick($event)"
    (focus)="showProgressModal()"
    [value]="asHours"
    [disabled]="opened || inFlight"
    [id]="handler.htmlId"
  />

  <ng-container slot="body">
    <div
      class="spot-modal--header"
    >
      <div
        id="spotModalTitle"
        class="spot-modal--header-title"
        [textContent]="this.text.title">
      </div>

      <button
        class="button button_no-margin -transparent spot-modal--header-close-button"
        [attr.aria-label]="text.button_close"
        (click)="closeMe()"
        data-test-selector="op-progress-modal--close-icon"
      >
        <svg x-icon size="small" class="spot-modal--header-close-button-icon"></svg>
      </button>
    </div>

    <div class="spot-modal--body spot-container">
      <turbo-frame
        #frameElement
        id="work_package_progress_modal"
        [attr.src]="this.frameSrc">
        <op-content-loader
          viewBox="0 0 180 80"
        >
          <svg:rect height="10" rx="2" ry="2" width="110" x="0" y="0"/>
          <svg:rect height="10" rx="2" ry="2" width="25" x="120" y="0"/>
          <svg:rect height="10" rx="2" ry="2" width="25" x="155" y="0"/>
          <svg:rect height="25%" rx="2" ry="2" width="180" x="0" y="16"/>
        </op-content-loader>
      </turbo-frame>
    </div>
  </ng-container>
</spot-drop-modal>

<!-- Other modal-->
<!--<div-->
<!--  class="spot-modal spot-modal_wide op-share-wp-modal"-->
<!--&gt;-->
<!--  <div-->
<!--    class="spot-modal&#45;&#45;header"-->
<!--  >-->
<!--    <div-->
<!--      id="spotModalTitle"-->
<!--      class="spot-modal&#45;&#45;header-title"-->
<!--      [textContent]="this.text.title">-->
<!--    </div>-->
<!--    <button-->
<!--      class="button button_no-margin -transparent spot-modal&#45;&#45;header-close-button"-->
<!--      [attr.aria-label]="text.button_close"-->
<!--      (click)="closeMe($event)"-->
<!--      data-test-selector="op-share-wp-modal&#45;&#45;close-icon"-->
<!--    >-->
<!--      <svg-->
<!--        x-icon-->
<!--        size="small"-->
<!--        class="spot-modal&#45;&#45;header-close-button-icon"-->
<!--      ></svg>-->
<!--    </button>-->
<!--  </div>-->

<!--  <div class="spot-modal&#45;&#45;body spot-container">-->
<!--    <turbo-frame-->
<!--      #frameElement-->
<!--      id="work_package_progress_modal"-->
<!--      src="{{this.frameSrc}}">-->
<!--      <op-content-loader-->
<!--        viewBox="0 0 180 80"-->
<!--      >-->
<!--        <svg:rect height="10" rx="2" ry="2" width="110" x="0" y="0"/>-->
<!--        <svg:rect height="10" rx="2" ry="2" width="25" x="120" y="0"/>-->
<!--        <svg:rect height="10" rx="2" ry="2" width="25" x="155" y="0"/>-->
<!--        <svg:rect height="25%" rx="2" ry="2" width="180" x="0" y="16"/>-->
<!--      </op-content-loader>-->
<!--    </turbo-frame>-->
<!--  </div>-->
<!--</div>-->
